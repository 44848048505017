import React from 'react';
import PropTypes from 'prop-types';

import Layout from "../components/base/layout"
import SEO from "../components/base/seo"
import Header from "../components/base/header"

import GoogleMaps from "../components/search/googlemap/googlemaps"
import Results from "../components/search/results"
import FilterSideBar from "../components/search/filters/filtersidebar"
import MobileViews from "../components/search/mobile-views"

const LocationsPage = ({
  searchState,
  searchClient,
  onSearchStateChange
}) => {
  return(
    <Layout>
      <SEO title="Locations Search Page" />
      <Header/>
      {/* Mobile */}
          <MobileViews
            searchClient={searchClient}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
          />

      {/* Desktop */}
      <div className="show-for-large sl_container sl_container--search">
        <div className="sl_row">
            <div className="sl_cell sl_container__content large-8">
              <div className="sl_row">
                <div className="sl_cell sl_filter xlarge-3 large-4">
                  <div className="sl_sticky-container">
                  <FilterSideBar />
                  </div>
                </div>{/*end sl_cell*/}
                <div className="sl_cell sl_results xlarge-9 large-8">
                  <Results />
                </div>{/*end sl_cell*/}
              </div>{/*end sl_row*/}
            </div>{/*end sl_cell*/}
            <div className="sl_cell sl_container__map auto">
              <GoogleMaps />
            </div>{/*end sl_cell*/}
        </div>{/*end sl_row*/}
      </div>{/*end sl_content*/}
    </Layout>
  )
};

LocationsPage.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
  location: PropTypes.object.isRequired,
};

export default LocationsPage;