import React from "react"
import 'rheostat/initialize';

import { connectInfiniteHits } from 'react-instantsearch-dom'

import {
  GoogleMapsLoader,
  GeoSearch,
} from 'react-instantsearch-dom-maps';

import CustomNewMarker from './markers'

const apiKey = 'AIzaSyA1EFkKIdNERBa3ud5_SjESfBnNcV4hXLU';
const endpoint = 'https://maps.googleapis.com/maps/api/js?v=weekly';
const initialZoom = 12;
const initialPosition = {
  lat: 40.71,
  lng: -74.01,
};
 
const Maps = ({ 
  hits,
  }) => {

    const infiniteHits = hits;
    
    return (
        <div className="sl_googlemap">
            <GoogleMapsLoader apiKey={apiKey} endpoint={endpoint}>
                {google => (
                <GeoSearch 
                google={google}
                initialZoom={initialZoom}
                initialPosition={initialPosition}
                zoomControl={true}
                enableRefineOnMapMove={false}
                >
                { ({hits}) => (
                <>
                    {infiniteHits.map(hit => (
                      <CustomNewMarker key={hit.objectID} hit={hit} google={google} />
                    ))}
                </>
                )}
                </GeoSearch>
                )}
            </GoogleMapsLoader>
        </div>
    );    
};

const GoogleMaps = connectInfiniteHits(Maps);

export default GoogleMaps