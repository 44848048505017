import React from 'react';
import { connectStats } from 'react-instantsearch-dom';



const Stats = ({ hits, nbHits, nbSortedHits, areHitsSorted }) => (
  <p className="sl_results__count">
    Showing {hits.length.toLocaleString()}  of {nbHits.toLocaleString()} Locations
  </p>
);

const CustomStats = connectStats(Stats);

export default CustomStats;